<template>
  <div>
    <div class="row">
      <div class="col-12">
        <router-link
          v-if="$can({ key: 'providers', expectedPermission: 'edit' })"
          :to="{
            name: 'tasks.add',
            query: { taskType: '7', userId: providers.provider.user_id },
          }"
          class="btn btn-outline-blue mb-3"
        >
          <icon type="plus" /> Add Note
        </router-link>

        <router-link
          v-if="user != null && user.isProvider"
          :to="{ name: 'Tasks' }"
          class="btn btn-secondary mb-3 ml-3"
        >
          <icon type="envelope fa-lg" /> Inbox
        </router-link>
      </div>
    </div>

    <timeline :userId="providers.provider.user_id" />
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "timeline-provider",
  computed: {
    ...mapState(["providers"]),
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },

  data() {
    return {};
  },
};
</script>
